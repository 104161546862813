import React from 'react'
import { motion, AnimatePresence } from 'motion/react'
import clsx from 'clsx'

import { Box } from 'components/layout/box'
import * as styles from './BotProfile.css'
import { Card } from 'components/Card'
import { ProfileAvatar } from 'components/MavbotsArena/components/Avatar/Avatar'
import { Text } from 'components/typography/text'
// import { Badge } from 'components/MavbotsArena/components/Badge/Badge'
import { Trader } from 'components/MavbotsArena/data/data'
import { Button } from 'components/inputs/button'
// import { RiskChip } from 'components/MavbotsArena/components/RiskChip/RiskChip'
import { Stack } from 'components/layout/stack'
import { TradesList } from 'components/MavbotsArena/components/TradesList/TradesList'
import { BotProfileTabs } from './BotProfileTabs/BotProfileTabs'
import { BotProfileChart } from './BotProfileChart/BotProfileChart'
import { useArenaContext } from 'context/ArenaContext'
import { BotProfileWidgets } from './BotProfileWidgets/BotProfileWidgets'
import { useOrientation } from '@uidotdev/usehooks'

interface BotProfileProps {
	data: Trader
}

export const BotProfile = ({ data }: BotProfileProps) => {
	const { arenaSelectedBot } = useArenaContext()
	const { type: orientationType } = useOrientation()

	return (
		<Box className={styles.botProfileContainer}>
			<Card style={{ width: '100%' }}>
				{!arenaSelectedBot ? (
					<Text variant="body2" textAlign="center" color="neutral.300">
						Loading...
					</Text>
				) : (
					<>
						{/* Profile */}
						<Box className={styles.profileGrid}>
							<Box className={styles.sideBotProfileDetails}>
								{/* AVATAR */}
								<Box className={styles.profileAvatarWrapper} display="flex" gap={{ mobile: 4, desktop: 2 }}>
									<ProfileAvatar
										style={{ width: '200px', height: '200px' }}
										videoAvatar={arenaSelectedBot.videoAvatar}
									/>
									<Box display="flex" gap={{ mobile: 4, desktop: 2 }} flexDirection="column">
										<Box className={styles.profileAvatarDetails}>
											<AnimatePresence mode="wait" initial={false}>
												<motion.div
													key={arenaSelectedBot.bot_name}
													initial={{ opacity: 0, y: 0 }}
													animate={{ opacity: 1, y: 0 }}
													exit={{ opacity: 0, y: 0 }}
													transition={{ duration: 0.1 }}>
													<Text variant="h2" fontWeight="medium">
														{arenaSelectedBot.bot_name}
													</Text>
												</motion.div>
											</AnimatePresence>
											{/* <Box display="flex" gap={0.5}>
												{arenaSelectedBot?.badges.map((badge, index) => (
													<Badge key={index} badge={badge} />
												))}
											</Box> */}
										</Box>
										<Button variant="primary" size="small">
											Follow
										</Button>
									</Box>
								</Box>
								{/* Profile Details */}

								<Box className={styles.botProfileDetails}>
									<AnimatePresence mode="wait" initial={true}>
										<motion.div
											key={arenaSelectedBot.bot_name}
											initial={{ opacity: 0, y: 0 }}
											animate={{ opacity: 1, y: 0 }}
											exit={{ opacity: 0, y: 0 }}
											transition={{ duration: 0.2 }}>
											<TradesList trades={arenaSelectedBot?.trades} openPositions={arenaSelectedBot?.open_positions}>
												<Text as="span" variant="body1" color="neutral.100">
													Trades:{' '}
												</Text>
											</TradesList>
											<Text variant="body1" color="neutral.100">
												{arenaSelectedBot.description}
											</Text>
											<br></br>
											<Text variant="body1" color="neutral.100">
												{arenaSelectedBot.description}
											</Text>
										</motion.div>
									</AnimatePresence>
									{/* Strategy */}
									<Stack gap={2}>
										<AnimatePresence mode="wait" initial={true}>
											<motion.div
												key={arenaSelectedBot.bot_name}
												initial={{ opacity: 0, y: 0 }}
												animate={{ opacity: 1, y: 0 }}
												exit={{ opacity: 0, y: 0 }}
												transition={{ duration: 0.2 }}>
												<Text variant="body1Bold">
													Strategy:{' '}
													<Text as="span" color="primary.500" fontSize="small">
														{arenaSelectedBot?.strategy}
													</Text>
												</Text>
												<Text variant="body2">{arenaSelectedBot?.strategy_description}</Text>
											</motion.div>
										</AnimatePresence>
									</Stack>
								</Box>
							</Box>
							<Box className={styles.dataDetails}>
								<Box
									className={clsx({
										[styles.botProfileWidgetsWrapper]: orientationType === 'landscape-primary',
										[styles.botProfileWidgetsWrapperPortrait]: orientationType === 'portrait-primary'
									})}>
									<BotProfileWidgets />
								</Box>
								<Box className={styles.dataDetailsMain}>
									{/* PROFILE STRATEGY & RETURNS */}
									<Box className={styles.botProfileStrategyReturns}>
										{/* Returns */}
										<BotProfileChart data={arenaSelectedBot?.bot_details.cumulative_returns} />
									</Box>
									<Box className={styles.botProfileTabsWrapper}>
										<BotProfileTabs />
									</Box>
								</Box>
							</Box>
						</Box>
					</>
				)}
			</Card>
		</Box>
	)
}
