import axios from 'axios'

export const createChatUser = async (token: string | undefined) => {
	try {
		const response = await axios.post(
			`${process.env.REACT_APP_API_SERVER}/okta/create-chat-user`,
			{},
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		)

		if (response.status === 200) {
			console.log('Chat user created or already exists:', response.data)

			// Return the chat details
			return {
				chatId: response.data.chat_id,
				chatToken: response.data.chat_token,
				chatName: response.data.chat_name,
				chatNameUpdated: response.data.chat_name_updated
			}
		} else {
			console.error('Failed to create chat user:', response.data.message)
			return null
		}
	} catch (error) {
		console.error('Error creating chat user:', error)
		return null
	}
}
