import React from 'react'

import { Box } from 'components/layout/box'
import * as styles from './BotProfileTabs.css'
import { TabsProvider } from 'components/Tabs/TabsProvider'
import { Tabs } from 'components/Tabs'
import { useArenaContext } from 'context/ArenaContext'
import { SimpleTabsTable } from './components/SimpleTabsTable'
import { AdvancedTabsTable } from './components/AdvancedTabsTable'

export const BotProfileTabs = () => {
	const { arenaSelectedBot } = useArenaContext()
	return (
		<Box>
			<TabsProvider defaultTab="Summary" tabs={['Summary', 'Advanced', 'TradeHistory']}>
				<Tabs>
					<Tabs.Tab value="Summary">Summary</Tabs.Tab>
					<Tabs.Tab value="Advanced">Advanced</Tabs.Tab>
					<Tabs.Tab value="TradeHistory">Trade History</Tabs.Tab>

					<Tabs.Panel value="Summary">
						<Box className={styles.tabsLayout}>
							<SimpleTabsTable data={arenaSelectedBot?.bot_details.tabs.tab_1} />
						</Box>
					</Tabs.Panel>
					<Tabs.Panel value="Advanced">
						<Box className={styles.tabsLayout}>
							<SimpleTabsTable data={arenaSelectedBot?.bot_details.tabs.tab_2} isConquerLimited />
						</Box>
					</Tabs.Panel>
					<Tabs.Panel value="TradeHistory">
						<Box className={styles.tabsLayout}>
							<AdvancedTabsTable />
						</Box>
					</Tabs.Panel>
				</Tabs>
			</TabsProvider>
		</Box>
	)
}
