import React, { useEffect, useState } from 'react'

import { Box } from 'components/layout/box'
import * as styles from './Avatar.css'
import { Text } from 'components/typography/text'
import clsx from 'clsx'
import { useOktaAuth } from '@okta/okta-react'
import { useArenaContext } from 'context/ArenaContext'
import { redirectToCustomerPortal } from 'helpers/redirectToCustomerPortal'

interface AvatarProps {
	avatar?: string
	handleClick?: () => void
}

export const Avatar = ({ avatar, handleClick }: AvatarProps) => {
	return (
		<Box className={styles.avatar}>
			<Box className={styles.avatarBackground} />
			<Box className={styles.imgWrapper}>
				<img src={avatar} className={styles.img} />
			</Box>
			<Box className={styles.seeStatsLayer} onClick={handleClick}>
				See Stats
			</Box>
		</Box>
	)
}

interface ProfileAvatarProps {
	videoAvatar: string
	handleClick?: () => void
	shouldHover?: boolean
	style?: React.CSSProperties
}

export const ProfileAvatar = ({ videoAvatar, handleClick, shouldHover, style }: ProfileAvatarProps) => {
	const [videoSrc, setVideoSrc] = useState<string | undefined>()

	const { avatarVideos } = useArenaContext()

	useEffect(() => {
		const cacheVideo = async (videoUrl: string) => {
			try {
				// Try to open the cache
				const cache = await caches.open('video-cache')
				const cachedResponse = await cache.match(videoUrl)

				if (cachedResponse) {
					// If the video is already cached, use it
					setVideoSrc(videoUrl)
				} else {
					// Fetch the video and cache it
					const response = await fetch(videoUrl)
					if (response.ok) {
						await cache.put(videoUrl, response) // Cache the video
						setVideoSrc(videoUrl)
					} else {
						console.error('Error fetching video:', response.statusText)
					}
				}
			} catch (error) {
				console.error('Failed to cache video:', error)
				// Optionally, set the video source directly without caching in case of error
				setVideoSrc(videoUrl)
			}
		}

		if (videoAvatar && avatarVideos[videoAvatar]) {
			cacheVideo(avatarVideos[videoAvatar])
		}
	}, [videoAvatar, avatarVideos])

	return (
		<Box style={style} className={clsx(shouldHover ? styles.profileAvatarSmall : styles.profileAvatar)}>
			<Box className={clsx(shouldHover ? styles.avatarBackground : styles.profileAvatarBackground)} />
			<Box className={clsx(shouldHover ? styles.imgWrapper : styles.profileWrapper)}>
				{videoSrc && <video src={videoSrc} className={styles.img} autoPlay loop muted playsInline />}
			</Box>
			{/* Only show 'See Stats' when shouldHover is true */}
			{shouldHover && (
				<Box className={styles.seeStatsLayer} onClick={handleClick}>
					See Stats
				</Box>
			)}
		</Box>
	)
}

interface MeetAvatarProps {
	avatar: string
	botName: string
	isSelected?: boolean
	handleClick?: () => void
	isAvailable: boolean
}

export const MeetAvatar = ({ avatar, botName, isSelected, handleClick, isAvailable }: MeetAvatarProps) => {
	const [imageSrc, setImageSrc] = useState<string>()
	const { oktaAuth } = useOktaAuth()

	const { avatarImages } = useArenaContext()

	const handleCustomerPortalRedirect = () => {
		redirectToCustomerPortal(() => oktaAuth.getAccessToken())
	}

	useEffect(() => {
		if (avatar && avatarImages[avatar]) {
			setImageSrc(avatarImages[avatar])
		} else {
			setImageSrc(undefined) // Clear imageSrc if avatar is not found
		}
	}, [avatar, avatarImages])

	return (
		<Box onClick={handleClick} display="flex" flexDirection={'column'} gap={1.5}>
			<Box className={clsx(styles.meetAvatar, isSelected && styles.meetAvatarSelected)}>
				<Box className={clsx(styles.meetAvatarBackground, !isAvailable && styles.grayscale)} />
				<Box className={clsx(styles.imgWrapper, !isAvailable && styles.grayscale)}>
					{imageSrc && <img src={imageSrc} className={styles.img} />}
				</Box>

				{!isAvailable && (
					<>
						<Box className={styles.meetAvatarDisabledButtonWrapper}>
							<button className={styles.conquerButton} onClick={handleCustomerPortalRedirect}>
								conquer
							</button>
						</Box>
					</>
				)}
			</Box>

			<Text variant="body2Bold" color="neutral.50" textAlign="center">
				{botName}
			</Text>
		</Box>
	)
}
