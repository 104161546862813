// @ts-nocheck
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { LoginCallback, SecureRoute, Security, useOktaAuth } from '@okta/okta-react'
import axios from 'axios'
import React, { Suspense, createContext, useEffect, useMemo, useState } from 'react'
import { Navigate, Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import OktaGuard from './components/Auth/OktaGuard'
import SessionGuard from './components/Auth/SessionGuard'
import { oktaAuthConfig } from './config'
// import logoutUser from './helpers/logoutUser'
import RequiredAuth from './helpers/requiredAuth'
import useDisablePinchZoomEffect from './helpers/useDisablePinchZoomEffect'
import './style/app.css'
import MavbotsArenaPage from 'pages/MavbotsArenaPage'
import { MainAppProvider, useMainAppContext } from 'context/MainAppContext'
import oktaAuth from './oktaAuthInstance' // Use singleton
import { useHistory } from 'react-router-dom'
import { ChatProvider, useChatContext } from 'context/ChatContext'

const HerdPowerPage = React.lazy(() => import('./pages/HerdPowerPage'))
const HerdPulsePage = React.lazy(() => import('./pages/HerdPulsePage'))
const StateOfMarketPage = React.lazy(() => import('./pages/StateOfMarketPage'))
const NewsPage = React.lazy(() => import('./pages/NewsPage'))
const NexusPage = React.lazy(() => import('./pages/NexusPage'))
const NavigatorPage = React.lazy(() => import('./pages/NavigatorPage'))
const SifterPage = React.lazy(() => import('./pages/SifterPage'))
const DualLineChartPage = React.lazy(() => import('./pages/DualLineChartPage'))
const TrendingPage = React.lazy(() => import('./pages/TrendingPage'))
const LoginPage = React.lazy(() => import('./pages/LoginPage'))
const NotFoundPage = React.lazy(() => import('./pages/NotFoundPage'))
const ContactUs = React.lazy(() => import('./pages/ContactUs'))
const CTAPage = React.lazy(() => import('./pages/CTAPage'))
const SuccessPage = React.lazy(() => import('./pages/SuccessPage'))
const TOSPage = React.lazy(() => import('./pages/TOSPage'))
const PrivacyPage = React.lazy(() => import('./pages/PrivacyPage'))
const GuidePage = React.lazy(() => import('./pages/GuidePage'))
const TradeViewPage = React.lazy(() => import('./pages/TradeViewPage'))
const SessionCheckPage = React.lazy(() => import('./pages/SessionCheckPage'))
const WatchlistPage = React.lazy(() => import('./pages/WatchlistPage'))

const axiosClient = axios.create()

axiosClient.defaults.headers.common = {
	'Content-Type': 'application/json',
	Accept: 'application/json',
	'Access-Control-Allow-Origin': '*',
	'Access-Control-Allow-Credentials': true,
	'Access-Control-Allow-Headers': 'X-Requested-With,content-type'
}
const queryClient = new QueryClient()
// const oktaAuth = new OktaAuth(oktaAuthConfig)

const App = () => {
	const isBrowser = typeof window !== 'undefined'
	const {
		userId,
		setUserId,
		userGroup,
		setUserGroup,
		isSessionAllowedToShowData,
		setSessionAllowedToShowData,
		isSessionVerifiedAsCurrent,
		setSessionVerifiedAsCurrent,
		loadingWhileExtendingSession,
		setLoadingWhileExtendingSession,
		chatId,
		chatName,
		chatToken
	} = useMainAppContext()
	const { initializeChatClient } = useChatContext()

	const location = useLocation()
	const navigate = useNavigate()

	useDisablePinchZoomEffect()

	const restoreOriginalUri = async (_oktaAuth, originalUri) => {
		console.log('restoreOriginalUri', originalUri)

		if (!!originalUri && originalUri !== '/success') {
			navigate(toRelativeUrl(originalUri, window?.location?.origin), { replace: true })
		}
	}

	const customAuthHandler = (e: any) => {
		console.log('customAuthHandler', e)

		localStorage.removeItem('checked')

		localStorage.removeItem('email')

		sessionStorage.removeItem('sessionGuide')

		setSessionAllowedToShowData(false)

		setSessionVerifiedAsCurrent(false)

		// WE NEED TO INVALIDATE TOKEN on backend
		navigate('/login')

		// window.location.href = '/login'
	}

	const onAuthResume = () => {
		console.log('onAuthResume')

		navigate('/login')
	}

	const vh = useMemo(() => (window ? window.innerHeight * 0.01 : 0), [])

	const vw = useMemo(() => (window ? window.innerWidth * 0.01 : 0), [])

	const [refresh, setRefresh] = useState(false)

	const [refreshTime, setRefreshTime] = useState('No refresh')

	const [initialRouteTracked, setInitialRouteTracked] = useState(false)

	console.log(
		loadingWhileExtendingSession,

		' loadingWhileExtendingSession loadingWhileExtendingSession loadingWhileExtendingSession'
	)

	function cookieCheck(version = '') {
		const savedValue = localStorage?.getItem('herdForceVersion')

		if (savedValue !== version) {
			if (typeof window !== 'undefined') {
				localStorage.setItem('herdForceVersion', version)

				window.location.replace(window.location.href)
			}
		}
	}

	useEffect(() => {
		if (location.pathname === '/success') {
			return
		}

		cookieCheck(process.env.REACT_APP_VERSION)

		getLastModifedTime()

		console.log(`app version - ${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`)

		window.addEventListener('storage', e => {
			if (e.key === 'sessionCheckFail') {
				setSessionVerifiedAsCurrent(false)
				navigate('/session-check')
			} else if (e.key === 'updateStart') {
				setLoadingWhileExtendingSession(true)
			} else if (e.key === 'updateEnd') {
				setLoadingWhileExtendingSession(false)
			}
		})

		let hidden

		let visibilityChange

		if (typeof document.hidden !== 'undefined') {
			// Opera 12.10 and Firefox 18 and later support

			hidden = 'hidden'

			visibilityChange = 'visibilitychange'
		} else if (typeof document.mozHidden !== 'undefined') {
			hidden = 'mozHidden'

			visibilityChange = 'mozvisibilitychange'
		} else if (typeof document.msHidden !== 'undefined') {
			hidden = 'msHidden'

			visibilityChange = 'msvisibilitychange'
		} else if (typeof document.webkitHidden !== 'undefined') {
			hidden = 'webkitHidden'

			visibilityChange = 'webkitvisibilitychange'
		}
		if (typeof document.addEventListener === 'undefined' || typeof document[hidden] === 'undefined') {
			// doesn't support event listeners :(
		} else {
			// Handle page visibility change

			document.addEventListener(
				visibilityChange,

				function () {
					if (document[hidden]) {
						console.log('*****////****/////****')

						console.log('*****////****/////****')

						console.log('WENT TO SLEEP')

						console.log('*****////****/////****')

						console.log('*****////****/////****')
					} else {
						console.log('*****////****/////****')

						console.log('*****////****/////****')

						console.log('WOKE UP')

						console.log('*****////****/////****')

						console.log('*****////****/////****')
					}
				},

				false
			)
		}

		const getDataInterval = setInterval(() => {
			getLastModifedTime()
		}, 30 * 1000)

		return () => {
			clearInterval(getDataInterval)

			window.removeEventListener('storage', () => {})
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		console.warn('changed loadingWhileExtendingSession!', loadingWhileExtendingSession)
	}, [loadingWhileExtendingSession])

	useEffect(() => {
		console.warn('changed loadingWhileExtendingSession!', loadingWhileExtendingSession)
	}, [loadingWhileExtendingSession])

	useEffect(() => {
		console.warn('changed loadingWhileExtendingSession!', loadingWhileExtendingSession)
	}, [loadingWhileExtendingSession])

	useEffect(() => {
		// First we get the viewport height and we multiple it by 1% to get a value for a vh unit

		// Then we set the value in the --vh custom property to the root of the document

		document.documentElement.style.setProperty('--vh', `${vh}px`)

		document.documentElement.style.setProperty('--vw', `${vw}px`)

		window.addEventListener('resize', () => {
			// We execute the same script as before

			const vh = window.innerHeight * 0.01

			const vw = window.innerWidth * 0.01

			document.documentElement.style.setProperty('--vh', `${vh}px`)

			document.documentElement.style.setProperty('--vw', `${vw}px`)

			return () => {
				window.removeEventListener('resize')
			}
		})
	}, [vh, vw])

	// if (isBrowser && !initialRouteTracked) {
	// 	console.log('initialRouteTracked')
	// 	localStorage.setItem('tokenUpdating', false)

	// 	setInitialRouteTracked(true)
	// }

	// if (!initialRouteTracked && localStorage.getItem('okta-token-storage') !== null) {
	// 	console.log('session verified')

	// 	setSessionVerifiedAsCurrent(true)
	// }

	const getLastModifedTime = () => {
		const accessToken = oktaAuth.getAccessToken()

		axiosClient
			.get(`${process.env.REACT_APP_API_SERVER}/storage/last-modified-time`, {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			})
			.then(res => {
				const timeRes = new Date(res.data.time) // Ensure type safety by casting to string

				const refreshTimeDate = new Date(refreshTime)

				if (refreshTime === 'No refresh') {
					setRefreshTime(res.data.time) // Ensure type safety by casting to string
				} else if (timeRes !== refreshTimeDate) {
					// Use getTime() to compare dates
					setRefresh(true)

					setRefreshTime(res.data.time) // Ensure type safety by casting to string

					setTimeout(() => {
						setRefresh(false)
					}, 5 * 1000)
				}
			})
			.catch(error => {
				// Handle error here
				console.log('errror', error)
			})
	}

	const accessToken = oktaAuth.getAccessToken()

	useEffect(() => {
		if (isBrowser && !initialRouteTracked) {
			console.log('initialRouteTracked')
			localStorage.setItem('tokenUpdating', false)

			setInitialRouteTracked(true)

			if (localStorage.getItem('okta-token-storage') !== null) {
				console.log('session verified')
				setSessionVerifiedAsCurrent(true)
			}
		}
	}, [isBrowser, initialRouteTracked, setSessionVerifiedAsCurrent])

	const isAllowed = useMemo(
		() => !!isSessionAllowedToShowData && !!accessToken && !!isSessionVerifiedAsCurrent && !!userGroup,
		[isSessionAllowedToShowData, accessToken, isSessionVerifiedAsCurrent, userGroup]
	)

	const chatAllowedGroup = useMemo(
		() => userGroup === process.env.REACT_APP_INVEST || userGroup === process.env.REACT_APP_CONQUER,
		[userGroup]
	)

	useEffect(() => {
		if (chatId && chatToken && chatAllowedGroup) {
			initializeChatClient(chatId, chatName, chatToken)
		}
	}, [chatId, chatToken, chatName, userGroup])

	return (
		<Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
			<Suspense fallback={<></>}>
				<QueryClientProvider client={queryClient}>
					<OktaGuard />
					<SessionGuard />
					<Routes>
						<Route path="/login/callback" element={<LoginCallback onAuthResume={onAuthResume} />} />
						<Route path="/login" element={<LoginPage />} />
						<Route path="/cta" element={<CTAPage />} />
						<Route path="/success" element={<SuccessPage />} />
						<Route path="/session-check" element={<SessionCheckPage />} />
						<Route path="/" element={<Navigate replace to="/mavbots-arena" />} />
						<Route path="" element={<RequiredAuth isAllowed={isAllowed} onAuthRequired={customAuthHandler} />}>
							<Route path="/mavbots-arena" element={<MavbotsArenaPage onRefresh={refresh} />} />
							<Route path="/state-of-market" element={<StateOfMarketPage />} />
							<Route path="/news" element={<NewsPage />} />
							<Route path="/nexus" element={<NexusPage />} />
							<Route path="/decoder/navigator" element={<NavigatorPage />} />
							<Route path="/decoder/sifter" element={<SifterPage />} />
							<Route path="/herd/trending" element={<TrendingPage onRefresh={refresh} />} />
							<Route path="/herd/herdpower" element={<HerdPowerPage />} />
							<Route path="/herd/herd-pulse" element={<HerdPulsePage />} />
							<Route path="/watchlist" element={<WatchlistPage />} />
							{/* <Route path="/contact-us" element={<ContactUs />} /> */}

							{/* remove /dual-line_chart */}
							<Route path="/dual-line_chart/:id" element={<DualLineChartPage />} />
							{/* <Route path="/terms-of-services" element={<TOSPage />} /> */}
							{/* <Route path="/privacy" element={<PrivacyPage />} /> */}
							{/* remove /guide */}
							{/* <Route path="/guide" element={<GuidePage />} /> */}
							{/* <Route path="/tradingview/:id" element={<TradeViewPage />} /> */}
							<Route path="*" element={<NotFoundPage />} />
						</Route>
						<Route path="*" element={<NotFoundPage />} />
					</Routes>
				</QueryClientProvider>
			</Suspense>
		</Security>
	)
}

export default App
