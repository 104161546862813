import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './index.css'

import App from './App'
import { MainAppProvider } from 'context/MainAppContext'
import { ChatProvider } from 'context/ChatContext'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
	<BrowserRouter>
		<React.StrictMode>
			<MainAppProvider>
				<ChatProvider>
					<App />
				</ChatProvider>
			</MainAppProvider>
		</React.StrictMode>
	</BrowserRouter>
)
