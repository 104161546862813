import React from 'react'
import { motion } from 'motion/react'

import * as styles from './CardTextEl.css'
import { Error } from 'components/Error'

type ContentChild = {
	text: string
}

export const CardTextEl = ({ content, isError }: { content: ContentChild[]; isError?: boolean }) => {
	if (isError) {
		return <Error />
	}

	const itemVariants = {
		hidden: { opacity: 0, y: 20 },
		visible: { opacity: 1, y: 0, transition: { duration: 0.8 } }
	}

	return content?.map((content, contentIndex) => {
		return (
			<motion.div
				key={contentIndex}
				className={styles.text}
				initial="hidden"
				animate="visible"
				variants={itemVariants}
				dangerouslySetInnerHTML={{ __html: content.text }}></motion.div>
		)
	})
}
