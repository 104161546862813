import "src/style/theme.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/style/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA3WWWW7bMBCG33sKAUaABKhTcri7T5Il3iOLszmxXWdzUvTunaEDlEOyEmzDH0aj2X6Si/12+9L9/tZ18/lzOKzXO7HoxM/sv8T/52D2q6ecAtESqkUnC6QRVXYGoRAnObKIdoecuPQkZ37RASdh0c0gXTm9QBoF3Tm9RDr0Q1yGnF4V+V4vOmd9TlYYCXhmdIPIaoZuMTZjGbqjMHS0ccjpPdJxGKdJ5fShGfK6GfIj0l7RndMnpMGH2E853SA1I9053SLVWveGJbqjYgaIitn+IipBA8tiv+j2t5cXp0p975T93mn8xTkRZ7nRc9NIG2b00jSy3NNr08hzo7emUeBG71TodOX0gHSKEbuS0w9K3fcyDoqV+pPaokPPfUiUzswPFgqMCppZNxpvGUYJzYTQ3hmGFb3TBa051tSxHqbAuiBRSjM1gvbc2lKSgNO0ZNjR4ECQRYAoq5kLVkYmIhnSK5UdOP4S1xg1w6SucRmXk2P4iko1RDfyReWaSmWn2PMAV1+l6tkEyps2RsnNpO2V7hkm2UnjBR9jeZ+srVE8bhKe1EYBt14fx8RPvLCPRyyXPO6nhCdfBLhJeOwdD3Cb8HLQvFQkv2h6kHxOSH9jcE5wvE9tMJPhAT7TKgBaCDbI8oVa7JURvPOvx3Rs5E7ejnHHkafznkTSx57P4CEtc8F47vsjOQk68Hp/NjGQdjAXq9krQTazBNIONljwNQlIO3qUo2T1Bt2UPJg2ts0tBVA7c9y1GPPF3gEh7WwMoVw2282KMdSK2B3SR1r8muOuRosBXZLX5eqfKejCFNiuANdp+TsVHd0/0PPJWXpO4ccefTD7VRn9TXurv23t9XBH1FX4vj4DwANt5bXfdetsAI9Ea7+oMCgZygtqB6guVTKUli4Z6qp6FkVlS4aK8iV7oT6X8JXOCSV8ozLJKqB3wqpO80Dc1rX6+A//bHZB0SHu3Ndc1t1R0OyOUq3uqHSeq+NQpm6PstSe2tS1uqZQSporR6GYbIFQTK5AqCVfINRMKBBqIxiOVvUpVNG5ThUMx18aYn/+Aus/j0EtCwAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/MavbotsArena/Roster/BotProfile/BotProfileTabs/BotProfileTabs.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/MavbotsArena/Roster/BotProfile/BotProfileTabs/BotProfileTabs.css.ts.vanilla.css\",\"source\":\"Ll8xcnU0Y2kzMCB7CiAgbWFyZ2luLXRvcDogdmFyKC0tczl4a2twMmkpOwogIGJhY2tncm91bmQ6IHZhcigtLXM5eGtrcHEpOwogIGJvcmRlci1yYWRpdXM6IDhweDsKfQouXzFydTRjaTMxIHsKICBjb2xvcjogdmFyKC0tczl4a2twaSk7CiAgb3ZlcmZsb3c6IGhpZGRlbjsKfQouXzFydTRjaTMyIHsKICBkaXNwbGF5OiBmbGV4OwogIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjsKICBhbGlnbi1pdGVtczogY2VudGVyOwogIGdhcDogdmFyKC0tczl4a2twMmcpOwogIHBhZGRpbmc6IHZhcigtLXM5eGtrcDJnKSB2YXIoLS1zOXhra3AyaSk7CiAgcG9zaXRpb246IHJlbGF0aXZlOwp9Ci5fMXJ1NGNpMzI6YmVmb3JlIHsKICBjb250ZW50OiAiIjsKICBwb3NpdGlvbjogYWJzb2x1dGU7CiAgYm90dG9tOiAwOwogIGxlZnQ6IHZhcigtLXM5eGtrcDJpKTsKICByaWdodDogdmFyKC0tczl4a2twMmkpOwogIGhlaWdodDogMXB4OwogIGJvcmRlci1yYWRpdXM6IDUwJTsKICBiYWNrZ3JvdW5kOiB2YXIoLS1zOXhra3BvKTsKfQouXzFydTRjaTMyOmxhc3Qtb2YtdHlwZTpiZWZvcmUgewogIGNvbnRlbnQ6IG5vbmU7Cn0KLl8xcnU0Y2kzMyB7CiAgZmxleC1ncm93OiAxOwogIGNvbG9yOiB2YXIoLS1zOXhra3BqKTsKICBkaXNwbGF5OiBmbGV4OwogIGdhcDogNHB4Owp9Ci5fMXJ1NGNpMzQgewogIGNvbG9yOiB2YXIoLS1zOXhra3AxMSk7Cn0KLl8xcnU0Y2kzNSB7CiAgY29sb3I6IHZhcigtLXM5eGtrcDIxKTsKfQouXzFydTRjaTM2IHsKICBmb250LXdlaWdodDogYm9sZDsKfQouXzFydTRjaTM3IHsKICBkaXNwbGF5OiBncmlkOwogIGdyaWQtdGVtcGxhdGUtY29sdW1uczogMWZyIDFmciAxZnI7CiAgYWxpZ24taXRlbXM6IGNlbnRlcjsKICBnYXA6IDZweDsKICB0ZXh0LXdyYXA6IG5vd3JhcDsKfQouXzFydTRjaTM4IHsKICBtYXJnaW4tbGVmdDogYXV0bzsKfQ==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var boldSymbol = '_1ru4ci36';
export var greenMessage = '_1ru4ci34';
export var listItem = '_1ru4ci37';
export var listRow = '_1ru4ci32';
export var listTableWrapper = '_1ru4ci31';
export var message = '_1ru4ci33';
export var redMessage = '_1ru4ci35';
export var size = '_1ru4ci38';
export var tabsLayout = '_1ru4ci30';