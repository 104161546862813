import { Box } from 'components/layout/box'
import { Text } from 'components/typography/text'
import * as styles from '../BotProfileTabs.css'

export const TableRow = ({ label, value }: { label: string; value: string }) => {
	return (
		<Box className={styles.listRow}>
			<Text variant="body2Bold" color="neutral.100">
				{label}
			</Text>
			<Text variant="body2Bold" color="neutral.100">
				{value}
			</Text>
		</Box>
	)
}
