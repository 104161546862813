import clsx from 'clsx'
import React from 'react'
import * as Popover from '@radix-ui/react-popover'
import { NavLink, useMatch } from 'react-router-dom'

import * as styles from '../Sidebar.css'
import { MenuItem } from '../data'
import { Box } from 'components/layout/box'
import { BlockIcon } from 'components/icons/block-icon'
import { DoubleArrowIcon, MarketIcon } from 'assets/icons/icons'
import { IconButton } from 'components/inputs/icon-button'

interface MobileNavItemProps extends MenuItem {
	isOpen: boolean
	onOpenChange: (open: boolean) => void
	onClose: () => void
}

export const MobileNavItem = ({ to, mobileLabel, isOpen, subNav, icon, onOpenChange, onClose }: MobileNavItemProps) => {
	const match = useMatch(to || '')
	const isActiveLink = Boolean(match)

	const subNavMatches = subNav.map(item => useMatch(item.to || ''))
	const isAnySubNavActive = subNavMatches.some(subMatch => subMatch !== null)

	if (to) {
		return (
			<NavLink to={to} className={styles.mobileNavLink} onClick={onClose}>
				<Box className={clsx(styles.mobileNavItem, isActiveLink && styles.mobileNavItemActive)}>
					<BlockIcon icon={icon} size="medium" />
					{mobileLabel}
				</Box>
			</NavLink>
		)
	}

	return (
		<Popover.Root open={isOpen} onOpenChange={onOpenChange}>
			<Popover.Trigger asChild>
				<div
					className={clsx(
						styles.mobileNavItem,
						styles.mobileNavButton,
						(isActiveLink || isAnySubNavActive) && styles.mobileNavButtonActive
					)}>
					<Box className={styles.iconsWrapper}>
						<BlockIcon icon={icon} size="medium" />
						<Box className={styles.doubleArrowIcons}>
							<BlockIcon icon={DoubleArrowIcon} size="small" />
						</Box>
					</Box>
					{mobileLabel}
				</div>
			</Popover.Trigger>
			<Popover.Content align="center" alignOffset={8} className={styles.mobileNavSubItemContainer}>
				{subNav.map((item, index) => {
					const isSubNavItemActive = subNavMatches[index] !== null
					return (
						<NavLink to={item.to} className={styles.mobileNavLink} key={item.to} onClick={onClose}>
							<Box className={clsx(styles.mobileNavSubItem, isSubNavItemActive && styles.mobileNavSubItemActive)}>
								<IconButton variant="mobileNavIcon" size="small">
									<BlockIcon icon={MarketIcon} size="small" />
								</IconButton>
								{item.label}
							</Box>
						</NavLink>
					)
				})}
			</Popover.Content>
		</Popover.Root>
	)
}
