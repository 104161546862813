import { Box } from 'components/layout/box'
import * as styles from '../BotProfileTabs.css'
import { TableRow } from './TableRow'
import { TabCard } from 'api/mavbots-arena/fetchBotStats'
import { Text } from 'components/typography/text'
import { useMainAppContext } from 'context/MainAppContext'
import { NotAvailable } from 'components/NotAvailable/NotAvailable'

// type RiskLevel = 1 | 2 | 3

// const getRiskLevelText = (riskLevel: RiskLevel) => {
// 	switch (riskLevel) {
// 		case 1:
// 			return 'Low'
// 		case 2:
// 			return 'Medium'
// 		case 3:
// 			return 'High'
// 		default:
// 			return 'Unknown'
// 	}
// }

export const SimpleTabsTable = ({ data, isConquerLimited }: { data?: TabCard[]; isConquerLimited?: boolean }) => {
	const { userGroup } = useMainAppContext()
	const isConquer = userGroup === process.env.REACT_APP_CONQUER

	if (!isConquer && isConquerLimited) {
		return (
			<Box paddingY={4}>
				<NotAvailable inGroupAvailable="Conquer" />
			</Box>
		)
	}

	return (
		<Box className={styles.listTableWrapper}>
			{!data ? (
				<Box paddingY={4}>
					<Text variant="body2Bold" textAlign="center" color="neutral.300">
						No data available
					</Text>
				</Box>
			) : (
				data.map((row, index) => <TableRow key={index} label={row.label} value={row.value} />)
			)}
		</Box>
	)
}
