import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react'
import { StreamChat } from 'stream-chat'

interface ChatContextType {
	chatClient: StreamChat | null
	setChatClient: (client: StreamChat | null) => void
	isChatReady: boolean
	setIsChatReady: (ready: boolean) => void
	initializeChatClient: (chatId: string, chatToken: string, chatName: string) => Promise<void>
	disconnectChatClient: () => void
	isSidebarOpen: boolean
	setIsSidebarOpen: (open: boolean) => void
}

// Assuming your API key is in an environment variable
const apiKey = process.env.REACT_APP_CHAT_API || 'pq6ekcbqx2np'

const ChatContext = createContext<ChatContextType | undefined>(undefined)

// Custom hook to use the ChatContext
export const useChatContext = () => {
	const context = useContext(ChatContext)
	if (!context) {
		throw new Error('useChatContext must be used within a ChatProvider')
	}
	return context
}

interface ChatProviderProps {
	children: ReactNode
}

export const ChatProvider = ({ children }: ChatProviderProps) => {
	const [chatClient, setChatClient] = useState<StreamChat | null>(null)
	const [isChatReady, setIsChatReady] = useState<boolean>(false)
	const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false)

	// Function to initialize the chat client
	const initializeChatClient = async (chatId: string, chatName: string, chatToken: string) => {
		console.log('Initializing chat client...')
		console.log('Chat ID:', chatId)
		console.log('Chat Name:', chatName)
		console.log('Chat Token:', chatToken)
		try {
			const client = StreamChat.getInstance(apiKey)

			await client.connectUser(
				{
					id: chatId,
					name: chatName
				},
				chatToken
			)

			setChatClient(client)
			setIsChatReady(true)
		} catch (error) {
			console.error('Error initializing chat client:', error)
			setIsChatReady(false)
		}
	}

	// Function to disconnect the chat client
	const disconnectChatClient = () => {
		if (chatClient) {
			chatClient.disconnectUser()
			setChatClient(null)
			setIsChatReady(false)
		}
	}

	// Ensure chat client is cleaned up when the component unmounts
	useEffect(() => {
		return () => {
			disconnectChatClient()
		}
	}, [])

	return (
		<ChatContext.Provider
			value={{
				chatClient,
				setChatClient,
				isChatReady,
				setIsChatReady,
				initializeChatClient,
				disconnectChatClient,
				isSidebarOpen,
				setIsSidebarOpen
			}}>
			{children}
		</ChatContext.Provider>
	)
}
