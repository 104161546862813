import axios from 'axios'

export interface WidgetCard {
	order_id: number
	text_1: number | string
	text_2?: number | string
	text_3?: string
	date?: string
	chart_value?: number
	chart_label?: string
}

export interface TabCard {
	label: string
	value: string
}

export interface CumulativeReturns {
	time: string
	algo_perf: number
	benchmark_perf: number
}

export interface Badge {
	name: string
	icon: string
}

export interface Trader {
	bot_id: number
	bot_name: string
	description: string
	avatar: string
	videoAvatar: string
	riskLevel: 1 | 2 | 3
	badges: Badge[]
	strategy: string
	strategy_description: string
	trades: string[]
	open_positions: string[]
	perf_ytd: string
	perf_5y: string
	win_rate: string
	win_avg: string
	loss_rate: string
	loss_avg: string
	bot_details: {
		cumulative_returns: CumulativeReturns[]
		tabs: {
			tab_1: TabCard[]
			tab_2: TabCard[]
		}
		widgets: {
			value: WidgetCard
			total_return: WidgetCard
			risk_adjusted_return: WidgetCard
			risk_rating: WidgetCard
		}
	}
}

const axiosClient = axios.create()

axiosClient.defaults.headers.common = {
	'Content-Type': 'application/json',
	Accept: 'application/json',
	'Access-Control-Allow-Origin': '*',
	'Access-Control-Allow-Credentials': true,
	'Access-Control-Allow-Headers': 'X-Requested-With,content-type'
}

export const fetchBotStats = async (accessToken: string | undefined): Promise<Trader[]> => {
	const response = await axiosClient.get(`${process.env.REACT_APP_API_SERVER}/storage/arena/stats`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})

	return response.data
}
