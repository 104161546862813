import { ExternalLink } from 'assets/icons/icons'
import { InlineIcon } from 'components/icons/inline-icon'
import { Button } from 'components/inputs/button'
import { Box } from 'components/layout/box'
import { Text } from 'components/typography/text'
import { useMainAppContext } from 'context/MainAppContext'
import * as styles from './NotAvailable.css'
import { useOktaAuth } from '@okta/okta-react'
import { redirectToCustomerPortal } from 'helpers/redirectToCustomerPortal'

interface Props {
	inGroupAvailable: 'Dabble' | 'Invest' | 'Conquer'
}

export const NotAvailable = ({ inGroupAvailable }: Props) => {
	const { userGroup } = useMainAppContext()
	const { oktaAuth } = useOktaAuth()

	return (
		<Box display="flex" flexDirection="column" alignItems="center" gap={4} className={styles.container}>
			<Text color="neutral.50" style={{ textAlign: 'center' }}>
				This feature is not available for {userGroup} users
			</Text>
			<Button onClick={() => redirectToCustomerPortal(() => oktaAuth.getAccessToken())} variant="primary">
				{inGroupAvailable}
			</Button>
			<button className={styles.button} onClick={() => redirectToCustomerPortal(() => oktaAuth.getAccessToken())}>
				<Text variant="body2" color="primary.500" className={styles.link}>
					Upgrade Plan
					<InlineIcon icon={ExternalLink} color="primary.500" style={{ marginLeft: '4px' }} />
				</Text>
			</button>
		</Box>
	)
}
