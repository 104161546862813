import { Text } from 'components/typography/text'

import styles from './Loader.module.css'

const typedStyles: Record<string, string> = styles

type LoaderProps = {
	text?: string
}

const Loader = ({ text }: LoaderProps) => {
	return (
		<>
			<div className={typedStyles.lds_ring}>
				<div />
				<div />
				<div />
				<div />
			</div>
			{text && (
				<Text color="neutral.50" style={{ textAlign: 'center' }}>
					{text}
				</Text>
			)}
		</>
	)
}

export default Loader
