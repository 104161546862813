import "src/style/theme.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/style/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA3WWWW7bMBCG33sKAUaABKhTcri7T5Il3iOLszmxXWdzUvTunaEDlEOyEmzDH0aj2X6Si/12+9L9/tZ18/lzOKzXO7HoxM/sv8T/52D2q6ecAtESqkUnC6QRVXYGoRAnObKIdoecuPQkZ37RASdh0c0gXTm9QBoF3Tm9RDr0Q1yGnF4V+V4vOmd9TlYYCXhmdIPIaoZuMTZjGbqjMHS0ccjpPdJxGKdJ5fShGfK6GfIj0l7RndMnpMGH2E853SA1I9053SLVWveGJbqjYgaIitn+IipBA8tiv+j2t5cXp0p975T93mn8xTkRZ7nRc9NIG2b00jSy3NNr08hzo7emUeBG71TodOX0gHSKEbuS0w9K3fcyDoqV+pPaokPPfUiUzswPFgqMCppZNxpvGUYJzYTQ3hmGFb3TBa051tSxHqbAuiBRSjM1gvbc2lKSgNO0ZNjR4ECQRYAoq5kLVkYmIhnSK5UdOP4S1xg1w6SucRmXk2P4iko1RDfyReWaSmWn2PMAV1+l6tkEyps2RsnNpO2V7hkm2UnjBR9jeZ+srVE8bhKe1EYBt14fx8RPvLCPRyyXPO6nhCdfBLhJeOwdD3Cb8HLQvFQkv2h6kHxOSH9jcE5wvE9tMJPhAT7TKgBaCDbI8oVa7JURvPOvx3Rs5E7ejnHHkafznkTSx57P4CEtc8F47vsjOQk68Hp/NjGQdjAXq9krQTazBNIONljwNQlIO3qUo2T1Bt2UPJg2ts0tBVA7c9y1GPPF3gEh7WwMoVw2282KMdSK2B3SR1r8muOuRosBXZLX5eqfKejCFNiuANdp+TsVHd0/0PPJWXpO4ccefTD7VRn9TXurv23t9XBH1FX4vj4DwANt5bXfdetsAI9Ea7+oMCgZygtqB6guVTKUli4Z6qp6FkVlS4aK8iV7oT6X8JXOCSV8ozLJKqB3wqpO80Dc1rX6+A//bHZB0SHu3Ndc1t1R0OyOUq3uqHSeq+NQpm6PstSe2tS1uqZQSporR6GYbIFQTK5AqCVfINRMKBBqIxiOVvUpVNG5ThUMx18aYn/+Aus/j0EtCwAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/RightSidePanel/PanelApproach/PanelApproach.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/RightSidePanel/PanelApproach/PanelApproach.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA31UyY7bOBC9+yuIGAFsoGlo8dbyZZK4+5QPyC2gxJLEMUVqKMpWOuh/T5GybGmmPRBssFiPtbxaVpe3v5//iQLye0YIF00t2a+E5BK6A14wKQpFhYWqSUgGyoJx19Yw1eTaVEl/lMzCj0UcnC9Lp36jQnHoEhIeZu+zVe8h9B4+fhksR8DoDhRWaJVgFJIEq21DgDWAtkfg+AE4uoJ1a0fotUdnrWm0SUitRZ/QXZ+U+gyG1MyWHpoLKRPSGrmYp8VPJ/30iHG8G4+8CG7LhDgOHAUliKK0CdltzqWTU5adCqNbxWmmpfN+ZmZBafPcnU712dPmDOdSXxJSCs5BubuacS5UMYVHyuNTbTgYahgXLZZnva87EuDnDv6tHjgxgDyLM7hb0WgnuOv+CONckhSwOtDzpJEehUl8+jQ1x1J82Fpvzuo6IYE7Scjt9Wj65IM+Smt1dRMmIQtVghH2rsGWwSTQuuB9UWtmMITxU2f6MYd3nhMXv8ho4ZyhjcVsHm3cR4LPT3iOj9HuhYRjIRoLsRe+7o7fjl/I2gthHEZhRDZj2LbXrHfb9Y7svRBE7kPTwefZcog71b4m9ALpSVhaseaUECkUMHMPcZ7nuSvhcqi7e/b0GOYsOkvIR+XLAwmBLpMth8kc0vEgbv89iEZb7IJFwKFYHj6cpvVt9PqBGj2nGisi1H073PzsHvgJ91NP/29l388htiJS17fVOuj7DVmhw5wpNMLkwXftf1qj7J1BZ+konLauwWSY1k3ZlIy78QvcIOEvivFvHoXH591xXLwebI0+Ab3OfVh3jxDXgOavr6/bl81hyCZnlZC4ar9DB4qTl47dVI14w0KGq42B6hacX8UfEPQ83dxKq/FAM6/VNcuERW2win3PiI6mEt3SSnNIes4o17yAYYi40TXFhWfdUKayNQtMcbz2Um956FO/BnB+RoCsr/+EWPzcljJFyhZx/ESicPdEwmiDf972XxVwwUiTGQBFGPKyqLDpBpKjPZZl6e1Osh/ln0qdnQ7+7uOFdV1Z9FqxYW/R9SAPzgKXjbuYjBEh77P3P379CWY1BwAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var comingSoon = 'wzj9q2c';
export var contentBox = 'wzj9q25';
export var glassWrapper = 'wzj9q29';
export var icon = 'wzj9q26';
export var iconExpanded = 'wzj9q27';
export var notch = 'wzj9q24';
export var panelApproach = 'wzj9q20';
export var panelApproachClosing = 'wzj9q23';
export var panelApproachContent = 'wzj9q2b';
export var panelApproachExpanded = 'wzj9q21';
export var panelApproachOpening = 'wzj9q22';
export var title = 'wzj9q28';
export var topReflection = 'wzj9q2a';