import React, { useState } from 'react'

import { Box } from 'components/layout/box'

import * as styles from './Sidebar.css'
import { menuList } from './data'
import { MobileNavItem } from './components'

export const MobileSidebar = () => {
	const [openNav, setOpenNav] = useState<string | null>(null)

	const handleOpenChange = (navId: string, open: boolean) => {
		setOpenNav(open ? navId : null)
	}

	const handleClose = () => {
		setOpenNav(null)
	}

	const filteredMenuList = menuList.filter(item => item.to !== '/nexus')

	return (
		<Box className={styles.mobileSidebar}>
			{filteredMenuList.map(item => (
				<MobileNavItem
					{...item}
					key={item.label}
					isOpen={openNav === item.label}
					onOpenChange={open => handleOpenChange(item.label, open)}
					onClose={handleClose}
				/>
			))}
		</Box>
	)
}
