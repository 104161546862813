import { useState, useMemo, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import * as styles from './EconomicMobile.css'
import { Box } from 'components/layout/box'
import { fetchEconomic } from 'api/right-side-panel'
import { Text } from 'components/typography/text'

interface Props {
	filter: { label: string; value: string }
}

export const EconomicMobile = ({ filter }: Props) => {
	const {
		data: economicData,
		isLoading,
		isError
	} = useQuery({
		queryKey: ['calendar-economic', filter.value],
		queryFn: () => fetchEconomic(filter.value)
	})

	return (
		<Box display="flex" flexDirection="column" gap={4} overflow="auto" paddingTop={4}>
			<Box display="flex" flexDirection="column" gap={4}>
				{economicData?.map((item, index) => (
					<Box key={index} className={styles.economicCard}>
						<Text variant="body1Bold" color={'secondary.400'}>
							{item.event}
						</Text>
					</Box>
				))}
			</Box>
		</Box>
	)
}
