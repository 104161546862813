import React from 'react'
import clsx from 'clsx'

import { Box } from 'components/layout/box'
import * as styles from './BotProfileWidgets.css'
import {
	WidgetCardRiskRating,
	WidgetCardTotalReturn,
	WidgetCardValue,
	WidgetCardRiskAdjustedReturn
} from './WidgetCard'
import { useOrientation } from '@uidotdev/usehooks'
import { useArenaContext } from 'context/ArenaContext'
import { WidgetCard } from 'api/mavbots-arena/fetchBotStats'

const WidgetRenderer = ({ widgets }: { widgets: { [key: string]: WidgetCard } }) => {
	// Convert the widgets object to an array and sort by order_id
	const sortedWidgets = Object.entries(widgets).sort(([, a], [, b]) => a.order_id - b.order_id)

	return (
		<>
			{sortedWidgets.map(([key, widget]) => {
				switch (key) {
					case 'value':
						return <WidgetCardValue key={key} {...widget} />
					case 'total_return':
						return <WidgetCardTotalReturn key={key} {...widget} />
					case 'risk_adjusted_return':
						return <WidgetCardRiskAdjustedReturn key={key} {...widget} />
					case 'risk_rating':
						return <WidgetCardRiskRating key={key} {...widget} />
					default:
						return null
				}
			})}
		</>
	)
}

export const BotProfileWidgets = () => {
	const { arenaSelectedBot } = useArenaContext()
	const { type: orientationType } = useOrientation()
	return (
		<Box
			className={clsx({
				[styles.botProfileWidgetsWrapper]: orientationType === 'landscape-primary',
				[styles.botProfileWidgetsWrapperPortrait]: orientationType === 'portrait-primary'
			})}>
			{arenaSelectedBot && <WidgetRenderer widgets={arenaSelectedBot.bot_details.widgets} />}
		</Box>
	)
}
