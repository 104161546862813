import clsx from 'clsx'
import dayjs from 'dayjs'

import { Box } from 'components/layout/box'
import { Text } from 'components/typography/text'
import { useArenaContext } from 'context/ArenaContext'
import * as styles from '../BotProfileTabs.css'

export const AdvancedTabsTable = () => {
	const { arenaSelectedBot, arenaBotsTrades } = useArenaContext()
	const trades = arenaBotsTrades.find(trades => trades.bot_id === arenaSelectedBot?.bot_id)
	console.log('trades', trades)

	return (
		<Box className={styles.listTableWrapper}>
			{!trades ? (
				<Box paddingY={4}>
					<Text variant="body2" textAlign="center" color="neutral.300">
						No trades available. Come back later.
					</Text>
				</Box>
			) : (
				<Box style={{ overflow: 'auto' }}>
					{trades.trade_history.map(trade => (
						<Box key={trade.id} className={clsx(styles.listRow, styles.listItem)}>
							<Text variant="body2">{dayjs(trade.datetime).format('MMM D, YY h:mA')}</Text>
							<Box className={`${styles.message}`}>
								<Box display="flex" gap={2}>
									<Text
										className={trade.message.includes('Bought') ? styles.greenMessage : styles.redMessage}
										variant="body2">
										{trade.message.split(' ')[0]}
									</Text>

									<Text className={styles.boldSymbol} variant="body2">
										{trade.message.split(' ')[1]}
									</Text>
								</Box>

								<Text variant="body2">{trade.message.split(' ').slice(2).join(' ')}</Text>
							</Box>
							<Text className={styles.size} variant="body2">
								{trade.size}
							</Text>
						</Box>
					))}
				</Box>
			)}
		</Box>
	)
}
